import React from 'react';
import './App.css';
import AboutMe from './components/AboutMe';
import FollowingOnMastodon from './components/FollowingOnMastodon';
import Post from './components/Post';
import { BlogIndex, HiddenSlugs } from './blog/BlogIndex';
import _ from 'lodash';

const mastodonId = "109286822595448651";
const mastodonDomain = "toot.lgbt"

export default function App(props) {
  var slug = window.location.pathname.substring(1);
  var slugLookup = _.merge(HiddenSlugs, BlogIndex);

  return <div className="App">
    <section className="hero">
      <div className="hero-body">
        <p className="title fancy">
          OHGODJULIAWTF
        </p>
        <p className="subtitle">
          The cursed domain of a queer engineer
        </p>
      </div>
    </section>
    <div className='container'>
      <div className='columns'>
        <div className='column is-three-quarters'>
          {window.location.pathname !== '/' ?
            <Post
              key={slug || "404"}
              post={slugLookup.get(slug) || slugLookup.get("404")}></Post>
            :
            Array.from(BlogIndex.entries()).filter((entry) => entry[0] !== "404")
              .map((entry) => <Post key={entry[0]} post={entry[1]}></Post>)
          }
        </div>
        <div className='column is-one-quarter'>
          <AboutMe />
          <FollowingOnMastodon domain={mastodonDomain} userId={mastodonId} />
        </div>
      </div>
    </div>
    <footer className='footer mt-6 has-text-centered has-text-weight-light'>
      <p className='is-size-7 mb-3'>Built with &#x1F49C; in Glasgow, Scotland &#127988;&#917607;&#917602;&#917619;&#917603;&#917620;&#917631;</p>
      <p className='mb-3'><span className='is-size-7 pr-2'>Styled with  <a href="https://bulma.io"><img style={{ width: "4%" }} src='/bulma-logo.svg' /></a></span>
        <span>-</span>
        <span className='is-size-7 pl-2'>Hosted on  <a href="https://cloudflare.com"><img style={{ width: "7%" }} src='/cloudflare-logo.svg' /></a></span></p>
      <p className='is-size-7'>&#x00A9; {new Date().getFullYear()} Julia McGhee</p>
    </footer>
  </div>
}
