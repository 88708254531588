import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faMastodon, faGithub } from '@fortawesome/free-brands-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function AboutMe(props) {
    return <section className="about-me box">
        <p className="is-size-4 mt-4 mb-4">
            <span className="icon pr-3">
                <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
            </span>
            <span className="icon-text">About Me
            </span></p>

        <p className='mb-4 mt-4'>
            Julia is a tropical fish loving, Star Wars obsessed, coffee addicted cat mom who also happens to be a software engineer.
            <br />
            <br />
            This is her personal website for general shitposting and widgets she writes while learning how to React. All content on this site is a reflection of her personal opinions and experiences and not the position of, or speaking for, her employer.
        </p>
        <ul>
            <li>
                <FontAwesomeIcon icon={faMastodon} />
                <a rel="me" href="https://toot.lgbt/@admin">
                    <span className='icon-text has-text-grey-dark pl-2'>@admin@toot.lgbt</span>
                </a>
            </li>
            <li>
                <FontAwesomeIcon icon={faGithub} />
                <a href="https:///github.com/lazorgurl">
                    <span className='icon-text has-text-grey-dark pl-2'>lazorgurl</span>
                </a>
            </li>
        </ul>
    </section>
}

export default AboutMe;
