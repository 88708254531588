import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMastodon } from '@fortawesome/free-brands-svg-icons'

import React, { useState, useEffect } from "react";

function FollowingOnMastodon(props) {
    var [usersFollowed, setUsersFollowed] = useState([]);

    useEffect(() => {
        fetch("https://" + props.domain + "/api/v1/accounts/" + props.userId + "/following").then(resp =>
            resp.json().then(data => {
                setUsersFollowed(data.sort(() => 0.5 - Math.random()));
            }).catch(reason => {
                console.log("deserializing JSON failed: " + reason);
            })
        ).catch(
            reason => {
                console.log("fetching users followed failed: " + reason);
            }
        )
    }, []);

    return <section className="following-on-mastodon box">
        <p className="is-size-4 pb-2">
            <span className="icon pr-3">
                <FontAwesomeIcon icon={faMastodon}></FontAwesomeIcon>
            </span>
            <span className="icon-text">Following on Mastodon
            </span></p>
        <ul>
            {usersFollowed.slice(0, 5).map(user => {
                return <li key={user.acct} className="pb-1 pt-1">
                    <article className="media">
                        <figure className="media-left">
                            <p className="image is-64x64">
                                <a href={user.url}>
                                    <img src={user.avatar} alt={user.acct + "'s avatar"} />
                                </a>
                            </p>
                        </figure>
                        <div className="media-content">
                            <div className="content">
                                <a href={user.url} className="has-text-grey-dark">
                                    <p className="mb-0">{user.display_name}</p>
                                </a>
                                <p className="mt-0 is-size-7"> <small>@{user.acct}</small></p>
                            </div>
                        </div>
                    </article>
                </li>
            })}
        </ul >
    </section >
}

export default FollowingOnMastodon;