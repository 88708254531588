import React from 'react';
import ShakaVideoStreamer from 'vimond-replay/video-streamer/shaka-player'
import { Replay } from 'vimond-replay';
import 'vimond-replay/index.css';
import { isIOS } from 'react-device-detect';
import _ from 'lodash';

export default function MDVideo(props) {
    const replayOptions = {
        videoStreamer: {
            shakaPlayer: {
                customConfiguration: {
                    manifest: {
                        dash: {
                            ignoreEmptyAdaptationSet: true
                        }
                    }
                }
            }
        }
    };

    const hls_src = _.replace(props.src, /mpd/g, "hls");
    const playbackSource = {
        streamUrl: props.src,
        contentType: 'application/xml'
    };

    return isIOS ?
        <video className="pt-1" controls>
            <source src={hls_src} type="application/x-mpegURL"></source>
        </video >
        : <Replay className="pt-1" source={playbackSource} initialPlaybackProps={{ isPaused: true }} options={replayOptions} >
            <ShakaVideoStreamer></ShakaVideoStreamer>
        </Replay >;
}