import React from 'react';
import { MDHeader, MDSubHeader } from './MDHeader';
import MDParagraph from './MDParagraph';
import MDImage from './MDImage';
import MDCodeBlock from './MDCodeBlock';
import MDPre from './MDPre';
import MDVideo from './MDVideo';
import { useEffect, useState } from 'react';
import {
    ReactMarkdown
} from 'react-markdown/lib/react-markdown';
import RehypeVideo from 'rehype-video';
import DocumentMeta from 'react-document-meta';

function Post(props) {
    const [content, setContent] = useState("");
    const custom = { h1: MDHeader, h2: MDSubHeader, p: MDParagraph, img: MDImage, code: MDCodeBlock, pre: MDPre, video: MDVideo };
    useEffect(() => {
        fetch(props.post.post).then((resp) => resp.text().then((text) => setContent(text)));
    }, [props.post]);

    return <DocumentMeta {...props.post.meta}>
        <section className="post box">
            <ReactMarkdown components={custom} rehypePlugins={[[RehypeVideo, { test: /\/.*(\.mp4|\.mov|\.mpd)$/, details: false }]]}>{content}</ReactMarkdown>
            <p className='mt-5 is-size-7'>First published {new Date(props.post.publishedAt * 1000).toUTCString()} by Julia</p>
        </section>
    </DocumentMeta>
}

export default Post;
