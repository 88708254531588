import test from './posts/test.md'
import test2 from './posts/test2.md'
import fourohfour from './posts/404.md'
import devx from './posts/devx.md'
import dash from './posts/mpegdash.md'


class PublishedPost {
    constructor(publishedAt, post, meta = {}) {
        this.publishedAt = publishedAt;
        this.post = post
        this.meta = meta
    }
}

export const HiddenSlugs = new Map([
    ["devx", new PublishedPost(1685381724, devx, {
        title: 'DevX is Awesome',
        description: 'Reasons why you should work on DevX',
        canonical: 'https://ohgodjulia.wtf/devx',
    })],
    ["404", new PublishedPost(1670143486, fourohfour)],
    ["test", new PublishedPost(1670141593, test)],
    ["test2", new PublishedPost(1670141610, test2)],
    ["dash", new PublishedPost(1670517877, dash, {
        title: 'Streaming Video in Markdown',
        description: 'Streaming Video in Markdown with React & MPEG DASH',
        canonical: 'https://ohgodjulia.wtf/dash',
    })]
]);
export const BlogIndex = new Map([
]);
