import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import {
    nord
} from 'react-syntax-highlighter/dist/esm/styles/prism';
import React from 'react';

export default function MDCodeBlock(props) {
    return (
        <SyntaxHighlighter language={/language-(\w+)/.exec(props.className)[1] || null} style={nord} pretag="div" children={props.children} />
    );
}